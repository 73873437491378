.checkout-container .opc-progress-bar {
    box-sizing: border-box;
    margin: $indent__base 0;

    @include min-screen($screen__m) {
        padding-right: $indent__l;
        width: calc((100% / 3) * 2); // For 66.66%
    }

    .opc-progress-bar-item {
        width: 50%;

        &._active {
            &:before,
            & > span:before {
                background-color: $color-emerald;
            }
        }
    }
}
