.eu-vat-info {
    border: 1px solid $color-light-gray;
    padding: $indent__s;

    // Styling just for checkout page
    .checkout-cart-index & {
        margin: $indent__s 0;

        @media only screen and (min-width: 768px) {
            float: left;
            width: 73%;
        }
    }

    p {
        margin-bottom: 0;

        &:before {
            color: $color-emerald;
            content: '>';
            padding-right: $indent__xs;
        }
    }
}
