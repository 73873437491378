$header__color: $color-gray;
$header__color-secondary: $color-silver;

$header__link-color: $header__color;
$header__link-color-visited: $header__link-color;
$header__link-color-hover: $header__color-secondary;

$header-action-icon__color: $color-emerald;
$header-action-icon__font-size: 30px;
$header-action-icon__line-height: 30px;

$header-icon__font-size: 20px;
