// Column width for the page, should total 100%
$main-info-column__width: 45%;
$secondary-info-column__width: 20%;
$media-column__width: 35%;

// Spacing between icon and text
$icon__padding-right: 4px;
$icon__font-size: 15px;

// Border used in all places as part of design
$border__light-gray: 1px $color-gray82 solid;

// Font size for the price element
$price__font-size: 25px;

$description-tabs-mobile__font-size: 18px;
$description-tabs-desktop__font-size: $font-size__l;
