.read-more {
    line-height: $font-size__m;

    &.hidden {
        background-image: linear-gradient(180deg, $color-charcoal 0%, rgba(0, 0, 0, 0));
        display: block;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        overflow: hidden;
    }
}

.read-more-link {
    background-color: $color-gray82;
    height: 1px;
    position: relative;

    &.open {
        margin-top: $indent__m;
    }

    span {
        background: $color-white;
        border: 1px $color-gray82 solid;
        cursor: pointer;
        font-weight: $font-weight__semibold;
        height: $indent__base;
        left: 50%;
        padding: $indent__s;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
    }
}
