.header-action-icon {
    border-right: 1px $color-black-russian solid;
    padding-right: 15px;
    text-align: center;

    > span {
        @include lib-font-size(14);
        color: $color-white;
        font-family: $heading__font-family__base;
        font-weight: bold;
        margin: 0;
        text-transform: uppercase;
    }

    &:hover {
        text-decoration: none;
    }

    &.compare {
        @include lib-icon-font(
            $_icon-font-content: $icon-compare-full,
            $_icon-font-color: $header-action-icon__color,
            $_icon-font-size: $header-action-icon__font-size,
            $_icon-font-line-height: $header-action-icon__line-height
        );

        &::before {
            display: block;
        }
    }
}
