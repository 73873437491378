.product-widget-block {
    @include lib-css(padding, $indent__s 0);

    .border {
        @include lib-css(border, $border__light-gray);
        @include lib-css(padding, $indent__base $indent__l);
    }

    .chevron-list {
        list-style: none;
        margin: unset;
        padding-left: unset;

        .link {
            @include lib-css(padding, $indent__xs 0);

            &:after {
                @include lib-css(font-family, $icons__font-name);
                @include lib-css(content, $icon__link-box);
            }

            &:before,
            &:hover {
                @include lib-css(color, $color-emerald);
            }

            &:before {
                @include lib-css(font-weight, $font-weight__semibold);
                @include lib-css(padding-right, $indent__xs);

                content: '>';
            }

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }
    }
}
