.product-info-paybyfinance {
    .finance-from-product {
        align-items: center;
        background-color: $color-gray94;
        display: flex;
        flex-direction: column;
        font-family: "Oswald", sans-serif;
        justify-content: space-between;
        padding: 15px $indent__s;
        text-align: center;

        .js-finance-form-product {
            font-weight: 800;
            text-decoration: none;

            &,
            &:hover {
                color: $color-black;
            }

            .finance-price {
                color: $color-red10;
                font-size: 1.6rem;
            }
        }
    }

    .terms-and-conditions {
        font-size: 1.2rem;
        margin-bottom: 0;

        &,
        a {
            text-decoration: none;
            color: $color-charcoal;
        }
    }
}

#paybyfinance_tab #paybyfinance-main-block .variable-data {
    font-family: "Oswald", sans-serif;
}

