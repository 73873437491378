.header-action-icon.wishlist {
    @include lib-icon-font(
        $_icon-font-content: $icon-wishlist-full,
        $_icon-font-color: $header-action-icon__color,
        $_icon-font-size: $header-action-icon__font-size,
        $_icon-font-line-height: $header-action-icon__line-height
    );

    &::before {
        display: block;
    }
}
