.usp-container {
    .usp-row {
        border-bottom: 1px $color-gray82 solid;
        padding-top: 0;

        .usp-blocks-list {
            height: $usp_height;
        }
    }

    .usp-blocks-item {
        font-size: $usp_font-size;
        height: $usp_height;

        &,
        a,
        p {
            line-height: $usp_height;
        }

        a,
        p {
            display: inline-block;
            color: $color-black-pearl;
            text-decoration: none;
        }

        a:hover {
            color: $color-emerald;
        }

        img {
            display: inline-block;
            max-height: $usp_max-image-size;
            max-width: $usp_max-image-size;
            padding-right: $indent__xs;
            vertical-align: middle;
        }
    }
}
