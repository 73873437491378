.form .field .control._with-tooltip {
    input {
        width: 100%;
    }
}

.field {
    .field-tooltip .field-tooltip-content:before,
    .field-tooltip .field-tooltip-content:after {
        // prevents having to redefine viewport styles
        @include max-screen($screen__m) {
            left: calc(#{$checkout-tooltip-content__width} - 2px);
        }
    }
}

.checkout-shipping-address {
    .control._with-tooltip {
        .field-tooltip {
            right: $indent__xs;
        }
    }
}
