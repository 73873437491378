.product.attribute.description {
    .description-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        padding-left: 0;

        .description-item {
            display: inline-block;
            margin-bottom: 10px;
            width: 100%;

            @include min-screen($screen__m) {
                width: calc(100% / 3);
            }

            &:before {
                color: $color-emerald;
                content: '>';
                font-weight: 600;
                padding-right: 5px;
            }
        }
    }
}
