//
//  Navigation Variables
//  _____________________________________________

$navigation__background: $color-black-russian;
$navigation__border: '';

$navigation-level0-item__background: $navigation__background;
$navigation-level0-item__color: $color-white;

$navigation-level0-item__active__background: $color-gray20;
$navigation-level0-item__active__border-color: '';
$navigation-level0-item__active__border-style: '';
$navigation-level0-item__active__border-width: '';
$navigation-level0-item__active__color: $navigation-level0-item__color;

$submenu__padding-top: 10px;
$submenu__padding-right: 10px;
$submenu__padding-bottom: 10px;
$submenu__padding-left: 10px;

$submenu-item__text-decoration: none;

//
//  Desktop Navigation
//  _____________________________________________

$navigation-desktop__background: $navigation__background;

$navigation-desktop-level0-item__background: $color-black-russian;
$navigation-desktop-level0-item__color: $navigation-level0-item__color;

$navigation-desktop-level0-item__hover__background: '';
$navigation-desktop-level0-item__hover__color: $navigation-level0-item__color;

$navigation-desktop-level0-item__active__background: $navigation-level0-item__active__background;
$navigation-desktop-level0-item__active__border-color: '';
$navigation-desktop-level0-item__active__border-style: '';
$navigation-desktop-level0-item__active__border-width: '';
$navigation-desktop-level0-item__active__color: $navigation-level0-item__color;

$submenu-desktop__font-size: 12px;
$submenu-desktop__font-weight: $font-weight__semibold;
$submenu-desktop__padding: 10px;

$submenu-desktop-item__padding: 0 10px;
$submenu-desktop-item__color: $color-black-russian;
$submenu-desktop-item__text-decoration: none;

$submenu-desktop-item__hover__background: $color-white-smoke;
$submenu-desktop-item__hover__color: $color-emerald;

$submenu-desktop-item__active__border-width: 0;
