.page-products .product-display-lg {
    & ~ .products.wrapper.grid {
        .product.details.product-item-details {
            height: 65%;
            top: 35%;
        }

        .product-item-photo {
            height: 35%;
        }
    }
}

.page-products .products.wrapper {
    &.grid,
    &.list {
        .actions.product-item-actions {
            display: block;

            .actions-primary {
                display: block;
                width: 100%;

                .action.tocart {
                    padding: 15px;
                    width: 100%;

                    &.primary:hover {
                        background: $color-transparent;
                        color: $color-charcoal;
                    }
                }
            }

            .actions-secondary {
                box-sizing: border-box;
                display: flex;
                justify-content: space-evenly;
                margin-top: $indent__s;
                padding-left: 0;
                width: 100%;

                .action > span {
                    color: $color-black-pearl;
                    display: inline-block;
                    font-family: $heading__font-family__base;
                    font-size: $font-size__s;
                    height: auto;
                    margin: 0;
                    position: relative;
                    text-transform: uppercase;
                    vertical-align: middle;
                    width: auto;

                    &:hover {
                        color: inherit;
                    }
                }

                .action:before {
                    color: $color-gray68;
                    font-size: 16px;
                    line-height: 16px;
                }

                .action.tocompare {
                    display: inline-block;
                }
            }
        }

        .product.details.product-item-details {
            flex-direction: column;
            height: 75%;
            justify-content: space-between;
            position: relative;
            text-align: left;
            top: 25%;

            .price-box {
                margin: 0;
            }

            .rating-result > span:before {
                color: orange;
            }
        }

        .product.name.product-item-name{
            .product-item-link {
                color: $color-black-pearl;
            }
        }

        .product.product-item {
            box-sizing: border-box;
            padding-bottom: 20px;

            @include min-screen($screen__m) {
                padding: 10px;
            }
        }

        .product-image-container {
            display: block;
            height: 100%;
            margin: 0 auto;

            .product-image-wrapper {
                height: 100%;
                overflow: initial;
                width: auto;

                .product-image-photo {
                    width: auto;
                }
            }
        }

        .product-reviews-summary {
            margin: 0;
        }

        .product-item-description {
            font-size: $font-size__s;
            margin: 0;
            overflow: hidden;
            position: relative;

            .trimmed-description {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
            }

            .description-list {
                display: flex;
                flex-direction: column;
                list-style: none;
                padding-left: 0;
            }

            .description-item {
                margin-bottom: 10px;

                &:before {
                    color: $color-emerald;
                    content: '>';
                    font-weight: 600;
                    padding-right: 5px;
                }
            }
        }

        .product-item-info {
            background-color: white;
            border: 1px $color-gray82 solid;
            box-sizing: border-box;
            padding: 10px;
            position: relative;
            text-align: center;
        }
    }

    &.grid {
        .product.details.product-item-details {
            display: flex;
        }

        .product-details-bottom {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .product-image-container .product-image-photo {
            max-height: 100%;
        }

        .product-item-description {
            border-top: 1px $color-gray82 solid;
            box-sizing: border-box;
            padding-top: 10px;
        }

        .product-item-info {
            height: 570px;
        }

        .product-item-photo {
            box-sizing: border-box;
            height: 25%;
            left: 0;
            padding: $indent__s;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &.list {
        .product.details.product-item-details {
            display: inline-block;
            margin-top: 0;
            width: 65%;
        }

        .product-item-info {
            display: inline-block;
            height: unset;
            width: 100%;
        }

        .product-item-photo {
            display: inline-block;
            padding: 30px 0;
            width: 30%;

            .product-image-photo {
                max-height: 200px;
            }
        }
    }
}

/*
 * Below 768 on large display categories we want to have automatic height for the product cards.
 * Typically this would be merged into the above where the values are set but the above doesn't account for view type
 * Have to redefine the elements else will need to split the above into small and large display
 */
@include max-screen($screen__m - 1px) {
    .product-display-lg ~ .products.wrapper.grid {
        .product-item-info {
            height: auto;
        }

        .product-item-info,
        .product-item-photo,
        .product-image-wrapper,
        .product-image-photo,
        .product.details.product-item-details {
            position: initial;
        }
    }
}
