.product.lower {
    @include min-screen($screen__m) {
        display: inline-flex;
        overflow: hidden;
        width: 100%;
    }

    .left {
        @include min-screen($screen__m) {
            width: 80%;
        }
    }

    .right {
        @include min-screen($screen__m) {
            width: 20%;
        }
    }
}
