.widget.block {
    .block-title strong {
        font-size: 30px;
        font-weight: $font-weight__semibold;
    }

    .product-item-details {
        text-align: center;

        .product-item-name {
            min-height: calc(#{$indent__base} * 2);
        }
    }

    .product-item-info {
        display: block;
        text-align: center;
    }

    .block.widget .product-item-name a {
        color: $color-black-pearl;
    }

    .price-wrapper.price-including-tax {
        display: inline-block;
    }

    .actions-primary {
        width: 100%;
    }

    .price-box {
        font-family: $heading__font-family__base;
        font-weight: $font-weight__semibold;
        margin: $indent__s 0 $indent__s;
        min-height: calc(#{$indent__m} * 2);
        text-transform: uppercase;
    }

    .action.tocart.secondary {
        padding: $indent__s;
        width: 100%;
    }

    .product-image-container {
        border: $border__light-gray;
        box-sizing: border-box;
    }
}

.filter-active .widget.block {
    display: none;
}
