.footer {
    a {
        font-weight: $font-weight__regular;

        @include lib-link(
            $_link-color: $secondary__color,
            $_link-text-decoration: none,
            $_link-color-visited: $secondary__color,
            $_link-text-decoration-visited: none,
            $_link-color-hover: $color-emerald
        )
    }

    &.content {
        border: none;
        margin-top: 0;
        padding-top: 0;
    }

    &.info {
        color: $secondary__color;
        padding: 0 20px;

        @include max-screen($screen__m) {
            padding: 0;
        }

        h3 {
            color: $color-white;
            font-size: 22px;
            font-weight: $font-weight__bold;
            line-height: 32px;
        }

        img {
            float: left;
            padding-bottom: 5px;
            padding-top: 5px;
            width: 10%;
        }

        li {
            line-height: 25px;
        }

        &.first {
            padding-left: 0;
        }

        &.fourth {
            padding-right: 0;

            p {
                margin-left: 40px;
            }
        }

        &.full-width {
            grid-column: 1/-1; // Span all columns
            padding: 0;

            .widget {
                font-size: 1.2rem;
                margin: 0;
                width: 100%;
            }
        }
    }

    &.wrapper {
        border-top: 1px solid $color-emerald;
        display: grid;
        grid-template-columns: repeat(4, 1fr); // 4 columns

        div {
            flex: 1;
        }

        @include max-screen($screen__m) {
            grid-template-columns: 1fr // 1 column
        }
    }
}

.footer-bottom-wrapper {
    background-color: $color-black-russian;

    .footer-bottom-inner {
        display: block;
        margin: 0 auto;
        max-width: $layout__max-width;

        @include min-screen($screen__m) {
            @include lib-vendor-prefix-display;
        }

        .left,
        .right {
            @include lib-vendor-prefix-display;
            -ms-flex-pack: start;
            justify-content: flex-start;
            padding: 15px $indent__base;
            text-align: left;

            @include min-screen($screen__m) {
                padding: $indent__base;
                width: 50%;
            }

            small {
                color: $color-white;
                margin: auto 0;
                position: relative;
            }
        }

        .right {
            @include min-screen($screen__m) {
                -ms-flex-pack: end;
                justify-content: flex-end;
                text-align: right;
            }

            .widget {
                @include lib-vendor-prefix-display;
                margin: auto 0;
            }
        }
    }
}

.footer-top-wrapper {
    @include lib-vendor-prefix-display;
    @include lib-vendor-prefix-flex-direction;
    color: $color-white;
    margin: $indent__l auto;
    max-width: $layout__max-width;
    padding-top: 15px;
    text-align: left;

    @include min-screen($screen__m) {
        @include lib-vendor-prefix-flex-direction(row);
        text-align: center;
    }

    .content {
        margin: $indent__s 0;
    }

    .heading {
        font-family: $heading__font-family__base;
        font-size: 20px;
        font-weight: $font-weight__bold;
        text-transform: uppercase;
    }

    .left,
    .middle,
    .right {
        @include lib-vendor-prefix-flex-shrink(1);
        @include lib-vendor-prefix-flex-basis(0);
        display: inline-block;
        padding: $indent__s $indent__m;
    }

    .left,
    .right {
        @include lib-vendor-prefix-flex-grow(1);
    }

    .middle {
        @include lib-vendor-prefix-flex-grow(2);

        form {
            @include lib-vendor-prefix-display;
            -ms-flex-pack: start;
            height: 50px;
            justify-content: flex-start;
            margin: $indent__base auto;

            @include min-screen($screen__m) {
                -ms-flex-pack: center;
                justify-content: center;
                width: 70%;
            }

            input,
            button {
                @include lib-vendor-prefix-flex-shrink(1);
                @include lib-vendor-prefix-flex-basis(0);
                border: none;
            }

            input {
                @include lib-vendor-prefix-flex-grow(2);
                font-size: $font-size__s;
                height: 50px;
                padding: 15px;

                &::placeholder {
                    color: $color-black;
                }
            }
            button {
                @include lib-vendor-prefix-flex-grow(1);
                background-color: $color-emerald;
                color: $color-white;
                text-transform: uppercase;
                font-family: $heading__font-family__base;
                font-size: 18px;
            }
        }
    }

    .link-list {
        list-style: none;
        margin: 0;
        padding: 0;

        .link {
            display: inline-block;
            padding: $indent__s $indent__s $indent__s 0;

            @include min-screen($screen__m) {
                padding: $indent__s $indent__xs;
            }
        }

        a.social-icon {
            height: 35px;
            vertical-align: middle;
        }
    }

    .social-icon:before {
        color: $color-gray;
        font-size: 35px;
    }
}
