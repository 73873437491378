//
//  Magento Theme Variables
//  _____________________________________________

//  Header
$header__background-color: $color-black-pearl;
$header-icons-color: $color-white;
$header-icons-color-hover: $color-white;

$header-item-height: 50px;

$addto-color: $color-gray;
$addto-hover-color: $color-white;

$minicart-icons-color: $color-emerald;
$minicart-icons-color-hover: lighten($color-emerald, 5%);

//  Footer
$footer__background-color: $color-black-pearl;

// Primary action button/form width (add to cart/enquire)
$product-action__width: 65%;
$product-action__margin-left: 5%;

$product-count-mobile__font-size: 16px;
$product-count-desktop__font-size: 25px;
