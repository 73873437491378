.leaflet {
    .embed {
        border: none;
        height: $leaflet-embed-mobile__height;
        margin-bottom: $leaflet-embed__margin-bottom;
        width: 100%;

        @include min-screen($screen__m) {
            height: $leaflet-embed-desktop__height;
        }
    }

    .heading {
        font-weight: $leaflet-heading__font-weight;
    }
}
