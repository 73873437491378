.page-product-downloadable .product-add-form {
    .product-options-wrapper,
    .product-options-bottom {
        float: unset;
        text-align: center;
        width: 100%;
    }

    .product-options-wrapper {
        .fieldset .label {
            text-align: center;
        }
    }

    .product-options-bottom {
        .box-tocart {
            margin: 10px 0;
        }

        .product-addto-links {
            margin-top: 0;

            .action {
                font-weight: 300;
            }
        }
    }
}
