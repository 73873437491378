//
// Search Bar Styles
//  _____________________________________________

$mini-basket__icon-size: 45px !default;

.header.content {
    @include min-screen($screen__m) {
        @include lib-vendor-prefix-display(flex);
        justify-content: flex-start;
    }

    .block-search {
        @include min-screen($screen__m) {
            @include lib-vendor-prefix-flex-grow(2);
            @include lib-vendor-prefix-flex-shrink(1);
            @include lib-vendor-prefix-flex-basis(0);
            float: unset;
        }

        .actions .action.search {
            background-color: $color-emerald;
            line-height: $header-item-height;
            opacity: 1;
            padding: 0 14px;
            right: 0;

            &::before {
                color: $color-white;
                line-height: $header-item-height;
            }
        }

        .input-text {
            height: $header-item-height;

            &:focus::placeholder {
                color: transparent;
            }

            &::placeholder {
                color: $color-black-russian;
            }
        }
    }

    .logo,
    .header-actions {
        @include min-screen($screen__m) {
            @include lib-vendor-prefix-flex-shrink(1);
            @include lib-vendor-prefix-flex-basis(0);
            float: unset;
        }
    }

    .logo {
        @include min-screen($screen__m) {
            @include lib-vendor-prefix-flex-grow(3);
        }
    }

    .header-actions {
        display: none;
        justify-content: flex-end;

        @include min-screen($screen__m) {
            @include lib-vendor-prefix-flex-grow(2);
            display: flex;
        }

        div {
            padding: 0 10px;
        }
    }

    .minicart-wrapper {
        font-weight: bold;

        @include min-screen($screen__m) {
            @include lib-vendor-prefix-flex-grow(0.5);
            @include lib-vendor-prefix-flex-shrink(1);
            @include lib-vendor-prefix-flex-basis(0);
            @include lib-vendor-prefix-order(1);
            margin: 0;
        }

        .action.showcart::before {
            font-size: $mini-basket__icon-size;
        }
    }
}
