.grid12-1,
.grid12-2,
.grid12-3,
.grid12-4,
.grid12-5,
.grid12-6,
.grid12-7,
.grid12-8,
.grid12-9,
.grid12-10,
.grid12-11,
.grid12-12,
.grid-full, /* Alias for full-width unit */
.grid-col2-sidebar, /* Special grid units */
.grid-col2-main {
    display: inline;
    float: left;
    margin-left: 1%;
    margin-right: 1%;
}

.grid-container-spaced,
.grid-container {
    div {
        &:not(.cap),
        &.no-gutter,
        &.no-left-gutter,
        &.no-right-gutter {
            @include max-screen($screen__m) {
                margin-left: 0;
                margin-right: 0;
                width: 100%;
            }
        }
        &.grid12-3-m {
            width: 23%;
            @include max-screen($screen__m) {
                width: 48%;
                margin-left: 1%;
                margin-right: 1%;
            }
        }
    }
}

/* Basic grid units width */
.grid12-1 {
    width: 6.33%;
}

.grid12-2 {
    width: 14.66%;
}

.grid12-3 {
    width: 23%;
}

.grid12-4 {
    width: 31.33%;
}

.grid12-5 {
    width: 39.67%;
}

.grid12-6 {
    width: 48%;
}

.grid12-7 {
    width: 56.33%;
}
.grid12-8 {
    width: 64.67%;
}

.grid12-9 {
    width: 73%;
}

.grid12-10 {
    width: 81.33%;
}

.grid12-11 {
    width: 89.67%;
}

.grid12-12,
.grid-full {
    width: 98%;
}

/* Grid units without left gutter (margin) */
.no-left-gutter {
    &.grid12-1 {
        margin-left: 0;
        width: 7.33%;
    }

    &.grid12-2 {
        margin-left: 0;
        width: 15.66%;
    }

    &.grid12-3 {
        margin-left: 0;
        width: 24%;
    }

    &.grid12-4 {
        margin-left: 0;
        width: 32.33%;
    }

    &.grid12-5 {
        margin-left: 0;
        width: 40.67%;
    }

    &.grid12-6 {
        margin-left: 0;
        width: 49%;
    }

    &.grid12-7 {
        margin-left: 0;
        width: 57.33%;
    }

    &.grid12-8 {
        margin-left: 0;
        width: 65.67%;
    }

    &.grid12-9 {
        margin-left: 0;
        width: 74%;
    }

    &.grid12-10 {
        margin-left: 0;
        width: 82.33%;
    }

    &.grid12-11 {
        margin-left: 0;
        width: 90.67%;
    }

    &.grid12-12,
    &.grid-full {
        margin-left: 0;
        width: 99%;
    }
}

/* Grid units without right gutter (margin) */
.no-right-gutter {
    &.grid12-1 {
        margin-right: 0;
        width: 7.33%;
    }
    &.grid12-2 {
        margin-right: 0;
        width: 15.66%;
    }
    &.grid12-3 {
        margin-right: 0;
        width: 24%;
    }
    &.grid12-4 {
        margin-right: 0;
        width: 32.33%;
    }
    &.grid12-5 {
        margin-right: 0;
        width: 40.67%;
    }
    &.grid12-6 {
        margin-right: 0;
        width: 49%;
    }
    &.grid12-7 {
        margin-right: 0;
        width: 57.33%;
    }
    &.grid12-8 {
        margin-right: 0;
        width: 65.67%;
    }
    &.grid12-9 {
        margin-right: 0;
        width: 74%;
    }
    &.grid12-10 {
        margin-right: 0;
        width: 82.33%;
    }
    &.grid12-11 {
        margin-right: 0;
        width: 90.67%;
    }
    &.grid12-12,
    &.grid-full {
        margin-right: 0;
        width: 99%;
    }
}

/* Grid units without any gutter (margin) */
.no-gutter {
    &.grid12-1 {
        margin-left: 0;
        margin-right: 0;
        width: 8.33%;
    }
    &.grid12-2 {
        margin-left: 0;
        margin-right: 0;
        width: 16.66%;
    }
    &.grid12-3 {
        margin-left: 0;
        margin-right: 0;
        width: 25%;
    }
    &.grid12-4 {
        margin-left: 0;
        margin-right: 0;
        width: 33.33%;
    }
    &.grid12-5 {
        margin-left: 0;
        margin-right: 0;
        width: 41.67%;
    }
    &.grid12-6 {
        margin-left: 0;
        margin-right: 0;
        width: 50%;
    }
    &.grid12-7 {
        margin-left: 0;
        margin-right: 0;
        width: 58.33%;
    }
    &.grid12-8 {
        margin-left: 0;
        margin-right: 0;
        width: 66.67%;
    }
    &.grid12-9 {
        margin-left: 0;
        margin-right: 0;
        width: 75%;
    }
    &.grid12-10 {
        margin-left: 0;
        margin-right: 0;
        width: 83.33%;
    }
    &.grid12-11 {
        margin-left: 0;
        margin-right: 0;
        width: 91.67%;
    }
    &.grid12-12,
    &.grid-full {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}

.grid-container-spaced {
    clear: both;

    &:before,
    &:after {
        content: "";
        display: table;
        line-height: 0;
    }

    &:after {
        clear: both;
    }

    .grid12-1,
    .grid12-2,
    .grid12-3,
    .grid12-4,
    .grid12-5,
    .grid12-6,
    .grid12-7,
    .grid12-8,
    .grid12-9,
    .grid12-10,
    .grid12-11,
    .grid12-12,
    .grid-full {
        margin-bottom: 2%;
    }
}


.cap {
    background-color: rgba(91, 210, 236, 0.85); /* #5bd2ec */
    bottom: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: $color-white;
    height: 100%;
    left: 0;
    margin: 0;
    padding: 15px;
    position: absolute;
    top: 0;
    -moz-transition: all 400ms ease-in-out;
    -o-transition: all 400ms ease-in-out;
    -webkit-transition: all 400ms ease-in-out;
    transition: all 400ms ease-in-out;
    width: 100%;
    z-index: 1;

    h2 {
        font-size: $font-size__xl;

        @include max-screen($screen__m) {
            font-size: $font-size__m;
        }
    }

    p {
        font-size: $font-size__l;

        @include max-screen($screen__m) {
            font-size: $font-size__s;
        }
    }
}

.cap-no-bg {
    background: none;
}

.cap-center-horizontally {
    text-align: center;
}

.cap-center-vertically {
    bottom: 50%;
    height: auto;
    left: 0;
    right: 0;
    -webkit-transform: translateY(50%);
    -moz-transform: translateY(50%);
    -o-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
    top: auto;
}

.cap-top {
    bottom: auto;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
}

.cap-top-left {
    bottom: auto;
    height: auto;
    left: 0;
    right: auto;
    top: 0;
    width: auto;
}

.cap-top-right {
    bottom: auto;
    height: auto;
    left: auto;
    right: 0;
    top: 0;
    width: auto;
}

.cap-left {
    bottom: 0;
    height: 100%;
    left: 0;
    right: auto;
    top: 0;
    width: auto;
}

.cap-right {
    bottom: 0;
    height: 100%;
    left: auto;
    right: 0;
    top: 0;
    width: auto;
}

.cap-bottom {
    bottom: 0;
    height: auto;
    left: 0;
    right: 0;
    top: auto;
    width: 100%;
}

.cap-bottom-left {
    bottom: 0;
    height: auto;
    left: 0;
    right: auto;
    top: auto;
    width: auto;
}

.cap-bottom-right {
    bottom: 0;
    height: auto;
    left: auto;
    right: 0;
    top: auto;
    width: auto;
}

.ban {
    overflow: hidden;
    position: relative;

    .image {
        -webkit-transition: all 400ms ease-in-out;
        -moz-transition: all 400ms ease-in-out;
        -o-transition: all 400ms ease-in-out;
        transition: all 400ms ease-in-out;
        width: 100%;
    }
}

.ban-effect-1:hover,
.ban-effect-2 {
    .image {
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -o-transform: scale(1.2);
        -ms-transform: scale(1.2);
        transform: scale(1.2);
    }
}

.ban-effect-2:hover .image {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}

.ban-effect-3:hover .image {
    -webkit-transform: scale(1.3) rotate(8deg);
    -moz-transform: scale(1.3) rotate(8deg);
    -o-transform: scale(1.3) rotate(8deg);
    -ms-transform: scale(1.3) rotate(8deg);
    transform: scale(1.3) rotate(8deg);
}

.ban-effect-fade-out .image {
    opacity: 1;
}

.ban-effect-fade-out:hover .image {
    opacity: 0.75;
}

.fade-on-hover {
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
}

.nested-container {
    margin-left: -1%;
    width: 102%;
}
