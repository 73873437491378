.product-add-form .box-tocart .fieldset {
    display: flex;
    justify-content: space-around;

    @include screen($screen__m, $screen__xl) {
        flex-direction: column;
    }

    .control {
        border: 1px $color-emerald solid;

        * {
            display: inline-block;
            font-weight: $font-weight__semibold;
        }

        .mage-error[generated] {
            margin-left: calc((#{$indent__m} * 2) * -1);
            margin-top: $indent__xl;
            position: absolute;
        }

        .qty-decrease {
            text-align: right;
        }

        .qty-increase {
            text-align: left;
        }
    }

    .field.qty {
        box-sizing: border-box;
        display: inline-block;
        margin-bottom: calc(#{$indent__l} / 2);
        padding-right: unset;
        vertical-align: unset;
        width: 30%;

        @include screen($screen__m, $screen__xl) {
            width: 50%;
            margin: calc(#{$indent__l} / 2) auto;
        }

        * {
            display: inline-block;
            line-height: 56px; // Size of the button minus 2px for border
        }
    }

    .input-text.qty {
        border: none;
        height: 50px;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 40%;
    }

    .qty-control {
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -o-user-select: none;
        width: 25%;
    }
}
