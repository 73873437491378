.sidebar.sidebar-additional {
    @include lib-layout-columns__right();

    .actions-toolbar .action {
        text-decoration: none;
    }

    .action.delete {
        top: 50%;
        transform: translateY(-50%);

        ::before {
            color: $color-black;
        }

        :hover::before {
            color: $color-emerald;
        }
    }

    > .block {
        background-color: $color-white-smoke;
        margin-bottom: $indent__base;
        padding: 15px;

        &:not(:last-child) {
            margin-bottom: $indent__base;
        }

        .block-title {
            &.center-text {
                margin-bottom: 0;
                text-align: center;

                a {
                    display: block;
                    padding: ($indent__m * 2) 0;
                    text-decoration: none;
                }

                strong {
                    color: inherit;
                }
            }

            .counter,
            strong {
                color: $color-black;
                font-family: $heading__font-family__base;
                text-transform: uppercase;
            }

            .counter {
                font-size: 18px;
            }

            strong {
                font-size: $indent__m;
                font-weight: 600;
            }
        }
    }

    .block-compare {
        .actions-toolbar {
            @include lib-vendor-prefix-display;

            .primary,
            .secondary {
                @include lib-vendor-prefix-flex-grow(1);
                @include lib-vendor-prefix-flex-shrink(1);
                @include lib-vendor-prefix-flex-basis(0);
                padding: $indent__xs;
            }

            .primary .compare {
                padding: $sidebar__button-padding;
            }

            .secondary .action.clear {
                @include lib-vendor-prefix-display;
                height: 100%;
                justify-content: center;
                margin: 0;

                span {
                    align-self: center;
                }
            }
        }

        .product-item-link,
        .action.delete {
            color: $color-black;
        }

        .action.delete {
            top: 0;
            transform: unset;
        }
    }

    .product-item-info {
        @include lib-vendor-prefix-display;
        flex-wrap: wrap;

        .product-item-photo {
            @include lib-vendor-prefix-flex-grow(1);
            @include lib-vendor-prefix-flex-shrink(1);
            @include lib-vendor-prefix-flex-basis(0);
            position: relative;
            width: $sidebar__image-width;

            .product-image-wrapper {
                overflow: visible;
            }

            .product-image-photo {
                position: relative;
            }
        }

        .product-item-details {
            @include lib-vendor-prefix-flex-grow(3);
            @include lib-vendor-prefix-flex-shrink(1);
            @include lib-vendor-prefix-flex-basis(0);
            margin-left: 0;
            padding-left: $indent__s;

            .product-item-name {
                margin-right: 0;
            }

            .product-item-link {
                color: $color-black;
            }
        }

        .product-item-actions {
            @include lib-vendor-prefix-flex-grow(3);

            .actions-primary {
                display: inline-block;

                .tocart {
                    padding: $sidebar__button-padding;
                }
            }

            .actions-secondary {
                display: inline-block;
                position: relative;
                width: 25%;

                .delete {
                    &::before {
                        color: $color-black;
                    }

                    &:hover::before {
                        color: $color-emerald;
                    }
                }
            }
        }
    }
}
