.top-container {
    margin-bottom: $indent__s;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    padding: 0;

    .hero-cms {
        .slick-slider:not(.slick-initialized) > *,
        .slick-slider,
        .slick-slide {
            padding: 0;
        }

        .block {
            margin-bottom: 0;
        }

        img {
            width: 100%;
        }
    }
}
