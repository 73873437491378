.widget-blog-posts {
    .post-list {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin-left: 0;
        padding-left: 0;

        .item {
            display: block;
            text-align: center;
            width: 100% / 3;

            h2 {
                margin: $indent__s 0;
                text-align: left;
            }

            .post-image {
                height: 300px;
                overflow-y: hidden;
            }
        }
    }
}
