.social-icon {
    &:hover {
        text-decoration: none;
    }

    &.facebook {
        content: url("/media/social-icons/facebook.png");
    }

    &.instagram {
        content: url("/media/social-icons/instagram.png");
    }

    &.twitter {
        content: url("/media/social-icons/twitter.png");
    }

    &.youtube {
        content: url("/media/social-icons/youtube.png");
    }
}
