.product-enquire-form {
    display: flex;
    margin-bottom: calc(#{$indent__l} / 2);

    @include screen($screen__m, $screen__xl) {
        flex-direction: column;
    }

    .enquire-form {
        margin: 0 auto;
        width: 100%;

        .action.primary {
            font-family: $heading__font-family__base;
            font-size: 20px;
            padding: $indent__base 15px;
            text-transform: uppercase;
            width: 100%;

            @include screen($screen__m, $screen__l) {
                font-size: 15px;
            }

            @include screen($screen__m, $screen__xl) {
                display: block;
                margin: calc(#{$indent__l} / 2) auto 0;
                padding: $indent__base $indent__xs;
                width: 85%;
            }
        }
    }
}
