.aw-arp-container .aw-arp-container__item .price-box,
.column.main .product-info-price,
.product .product-details-bottom .price-box,
.page-product-grouped .product-add-form {
    .including-vat,
    .excluding-vat,
    .enquire {
        * {
            font-family: $heading__font-family__base;
            font-weight: $font-weight__semibold;
            text-transform: uppercase;
        }

        .price-wrapper {
            display: inline-block;
        }
    }

    .special-price,
    .normal-price,
    .minimal-price-link {
        .excluding-vat *,
        .enquire * {
            font-size: $category-page-primary__font-size;
        }

        .including-vat * {
            font-size: $category-page-secondary__font-size;
        }
    }

    .old-price {
        text-decoration: none;

        .including-vat {
            * {
                color: $color-gray68;
                font-size: $category-page-primary__font-size;
            }

            .price-wrapper {
                text-decoration: line-through;
            }
        }
    }

    .special-price {
        .excluding-vat {
            * {
                color: $color-red10;
            }

            .price-wrapper:before {
                content: attr(data-label) ': ';
            }
        }

        .including-vat * {
            color: $color-black-pearl;
        }
    }

    .normal-price,
    .minimal-price-link {
        .enquire,
        .excluding-vat {
            * {
                color: $color-black-pearl;
            }
        }

        .excluding-vat {
            .price-wrapper:before {
                content: attr(data-label) ': ';
            }
        }

        .including-vat {
            * {
                color: $color-gray68;
            }

            .price-wrapper:first-child:before {
                content: attr(data-label) ': ';
            }
        }
    }

    .minimal-price-link {
        &,
        &:hover {
            margin-top: 0;
            text-decoration: none;
        }
    }
}

.column.main .product-info-price .price-box {
    text-align: center;

    .special-price,
    .normal-price,
    .minimal-price-link {
        .excluding-vat *,
        .enquire * {
            font-size: $product-page-primary__font-size;
        }

        .including-vat * {
            font-size: $product-page-secondary__font-size;
        }
    }
}

.prices-tier.items {
    .excluding-vat,
    .including-vat {
        display: inline-block;
    }

    .including-vat .price-wrapper {
        &:after,
        &:before {
            font-size: $product-page-tiered__font-size;
        }

        &:after {
            content: ')';
        }

        &:before {
            content: '(Inc VAT: ';
        }
    }
}
.price-excluding-tax + .price-including-tax,
.weee[data-label] {
    @include lib-font-size($cart-inc-vat__font-size);
    display: block;

    &:before {
        @include lib-font-size($default-inc-vat__font-size);
        content: attr(data-label) ': ';
    }

    .price {
        @include lib-font-size($default-inc-vat__font-size);
    }
}

.price-container {
    .price {
        @include lib-font-size($default-ex-vat__font-size);
    }

    .price-excluding-tax + .price-including-tax,
    .weee {
        @include lib-css(margin-top, $indent__xs);
    }

    .price-excluding-tax + .price-including-tax,
    .weee,
    .price-excluding-tax + .price-including-tax .price,
    .weee .price,
    .weee + .price-including-tax:before,
    .weee + .price-including-tax .price {
        @include lib-font-size($default-inc-vat__font-size);
    }
}

.opc-block-summary {
    .product-item {
        .price {
            @include lib-css(font-weight, $font-weight__regular);
            @include lib-font-size($summary-ex-vat__font-size);
        }

        .price-excluding-tax {
            & + .price-including-tax {
                margin: 0;

                .price {
                    @include lib-font-size($summary-inc-vat__font-size);
                }
            }
        }
    }
}
