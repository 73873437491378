.toolbar {
    .filter-by-title-container {
        align-items: center;
        cursor: pointer;
        display: flex;
        float: left;

        span {
            background-color: $color-white-smoke;
            border: 1px solid $color-gray82;
            color: $color-black-pearl;
            font-weight: $font-weight__bold;
            line-height: 30px; // height of container - 2px
            padding: 0 $indent__s;
        }

        @include min-screen($screen__m) {
            display: none;
        }
    }

    .sorter {
        align-items: center;
        display: flex;
        justify-content: flex-end;
        padding-top: 0;

        .sorter-label {
            white-space: nowrap;
        }

        .sorter-options {
            width: inherit;
        }
    }
}

.toolbar-wrapper {
    // Max screen used for simplicity and to prevent adding extra styles to reset defaults
    @include max-screen($screen__m) {
        > * {
            height: 32px;
            text-align: left;
            width: 50%;
        }
    }

    @include max-screen($screen__xs) {
        .filter-by-title-container {
            width: 30%;
        }

        .sorter {
            width: 70%;
        }
    }
}

#amasty-shopby-product-list .toolbar.toolbar-products:last-child {
    .filter-by-title-container {
        display: none;
    }
}

.block.filter {
    display: none;

    &.active {
        display: inline;
    }

    @include min-screen($screen__m) {
        display: block;
    }
}
