.amshopby-filter-current .items {
    padding-left: 0;

    .amshopby-item {
        margin-bottom: $indent__base;

        &:last-of-type {
            display: none;
        }

        .amshopby-filter-name {
            margin-right: $indent__s;
        }

        .amshopby-filter-value {
            font-weight: $font-weight__semibold;
        }
    }
}

.item .am-collapse-icon:after {
    right: $indent__xs;
}

.am-filter-go {
    display: none;
}

.am-filter-price {
    text-align: center;
}

.am-item-removed {
    &, a, span {
        opacity: 0.5;
    }
}

.am-shopby-item {
    transition: opacity 200ms;

    .action {
        display: block;
    }
}

.amshopby-slider-container {
    .am-slider.ui-slider {
        margin-top: 15px;

        &,
        .ui-slider-range {
            background-color: $color-black-pearl;
            height: 2px;
        }

        .ui-slider-handle {
            background-color: $color-emerald;
            // Height used to be 10px, it's now 2px so this needs to be moved up 4px
            // Diff in height/2 = 8/2 = 4 (it was previously -5px so now -9px)
            margin-top: -9px;
        }
    }

    .amshopby-slider-display {
        margin-top: $indent__s;
    }
}

.am-shopby-swatch-label {
    margin-top: 2px;
}

.am-swatch-link {
    transition: color 200ms;
}

.am-filter-items-attr_price {
    .delimiter {
        display: none;
    }

    .am-filter-price {
        &.-to {
            margin-left: auto;
        }
    }
}
