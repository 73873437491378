//
//  Fonts
//  _____________________________________________

//  Path
$icons__font-path: '../fonts/Scosarg-Theme-Icons/Scosarg-Theme-Icons';

//  Names
$icons__font-name: 'icons-scoasarg-theme';

$font-family__base: 'Gothic A1', sans-serif;
$heading__font-family__base: 'Oswald', sans-serif;

$font-size__xxl: 40px;
$font-size__xl: 30px;
$font-size__l: 20px;
$font-size__m: 16px;

//
//  Headings
//  _____________________________________________

$heading__color__base: $color-charcoal;
$h1__font-size: $font-size__xl;
$h1__font-size-desktop: $font-size__xxl;
$h2__font-size: $font-size__l;

//
//  Links
//  _____________________________________________

$link__color: $color-black;
$link__text-decoration: underline;
$link__hover__color: $color-emerald;

//
//  Lists
//  _____________________________________________

$list-item__margin-bottom: 0;
