.column.main {
    .product-info-main {
        order: 1;

        @include min-screen($screen__m) {
            box-sizing: border-box;
            float: left;
            padding: 0 $indent__base;
            width: $main-info-column__width;
        }
    }

    .product-info-secondary {
        order: 2;

        @include min-screen($screen__m) {
            float: right;
            width: $secondary-info-column__width;
        }

        .action.tocart {
            font-family: $heading__font-family__base;
            font-size: 20px;
            font-weight: $font-weight__semibold;
            padding: $indent__base 15px;
            text-transform: uppercase;

            @include screen($screen__m, $screen__l) {
                font-size: 15px;
            }

            @include screen($screen__m, $screen__xl) {
                padding: $indent__base $indent__xs;
            }
        }

        .box-tocart {
            display: block;

            .actions {
                box-sizing: border-box;
                display: inline-block;
                margin-left: $product-action__margin-left;
                padding-top: unset;
                vertical-align: unset;
                width: $product-action__width;


                @include screen($screen__m, $screen__xl) {
                    margin: 0 auto;
                    width: 85%;
                }

                .action {
                    line-height: 16px;
                    width: 100%;
                }
            }
        }

        .product-addto-links {
            display: flex;
            justify-content: space-between;

            .action {
                border: $border__light-gray;
                box-sizing: border-box;
                color: $color-black-pearl;
                font-size: $font-size__s;
                padding: $indent__xs;
                text-align: center;
                text-decoration: none;
                width: 48%;

                &:before {
                    padding-right: $icon__padding-right;
                }
            }

            .tocompare {
                @include lib-icon-font(
                    $_icon-font-content: $icon-compare-full,
                    $_icon-font-color: $color-emerald,
                    $_icon-font-size: $icon__font-size,
                    $_icon-font-line-height: $font-size__s
                );
            }

            .towishlist {
                @include lib-icon-font(
                    $_icon-font-content: $icon-wishlist-full,
                    $_icon-font-color: $color-emerald,
                    $_icon-font-size: $icon__font-size,
                    $_icon-font-line-height: $font-size__s
                );
            }
        }

        .product-add-form,
        .product-social-links {
            padding-top: $indent__s;
        }
    }

    .product.lower {
        order: 3;
    }

    .product.media {
        z-index: 1;

        @include min-screen($screen__m) {
            float: left;
            width: $media-column__width;
        }
    }
}

.catalog-product-view {
    &.page-product-grouped {
        .box-tocart {
            padding-top: $indent__s;
        }
    }
    .product-info-price {
        border-top: $border__light-gray;
        border-bottom: $border__light-gray;
        padding: $indent__m 0;
    }
}
