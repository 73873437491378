.nav-sections {
    .switcher-currency * {
        color: $color-white;

        .switcher-option a {
            color: $color-gray;
        }
    }

    .switcher-dropdown {
        display: none;
        padding: $indent__s 0;
    }

    .switcher-options {
        &.active .switcher-dropdown {
            display: block;
        }
    }

    .nav-sections-items {
        .section-item-title {
            color: $color-white;
            text-decoration: none;

            .nav-sections-item-switch {
                color: inherit;
                text-decoration: inherit;
            }
        }
    }
}
