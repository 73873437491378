.product-details-bottom,
.product-item-details,
.product-info-secondary {
    .product-details-stock {
        line-height: $container__height;
        min-height: $container__height;
        vertical-align: bottom;

        .available:before {
            color: $color-emerald;
            content: $icon__check-mark;
            font-family: $icons__font-name;
            font-size: $icon__font-size;
            speak: none;
            vertical-align: bottom;
        }
    }
}

.product-details-bottom,
.product-item-details {
    .product-details-stock {
        font-size: $font-size__s;
    }
}

.product-details-bottom {
    .product-details-stock {
        color: $color-black-pearl;
        font-family: $heading__font-family__base;
        margin-top: auto;
        text-transform: uppercase;
    }
}

.product-info-secondary {
    .product-details-stock {
        text-align: center;
        font-weight: $font-weight__semibold;
        margin-bottom: 2.5rem;
    }
}

.page-product-grouped {
    .product-details-stock {
        padding-top: 10px;
        text-align: left;
    }
}
