div.block.crosssell { // Div to increase css specificity
    float: left;
    position: relative;
    width: 100%;
    margin-top: 1.5rem;

    @media screen and (min-width: 768px) {
        width: 73%; // Matches the width of the main content area
    }

    .actions-primary {
        display: block;
    }

    .secondary-addto-links.actions-secondary {
        display: block;
        margin-top: 5px;
        padding-left: 0;

        .action:before {
            font-size: 24px;
        }
    }
}
