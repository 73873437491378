.product.data.items {
    > .data.item.title {
        margin: $indent__xs 0 0;
        position: relative;

        @include min-screen($screen__m) {
            margin-top: 0;
        }

        &::after { // Styles copied from the mega menu with minor modifications
            color: #47ce7a;
            content: '\e61c'; // Plus symbol from Magezon mega-menu
            font-size: 20px;
            font-family: 'Magezon-Icons' !important;
            font-weight: 900;
            line-height: 1;
            position: absolute;
            right: 5%;
            top: 50%;
            speak: none;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            @include min-screen($screen__m) {
                content: unset; // Don't need the symbol on desktop it turns into tabs
            }
        }

        &.active::after {
            content: '\e60d'; // Minus symbol from Magezon mega-menu

            @include min-screen($screen__m) {
                content: unset; // Don't need the symbol on desktop it turns into tabs
            }
        }

        > .switch {
            border: 1px $color-gray82 solid;
            border-bottom: none;
            color: $color-black-pearl;
            font-family: $heading__font-family__base;
            font-size: 15px;
            padding: 15px;
            text-transform: uppercase;

            @include min-screen($screen__m) {
                font-size: 13px;
                padding: 14px 5px;
            }

            @include min-screen(870px) {
                font-size: 14px;
                padding: 14px 10px;
            }

            @include min-screen($screen__l) {
                font-size: 17px;
                padding: 17px 10px;
            }

            @include min-screen(1170px) {
                font-size: 18px;
                padding: 18px;
            }

            @include min-screen($screen__xl) {
                font-size: 19px;
                padding: 19px;
            }

            &:hover {
                border: 1px $color-gray82 solid;
                border-bottom: none;
                color: $color-black-pearl;
            }
        }

        &:not(.active) > .switch:not(:hover) {
            border: none;
        }
    }

    > .data.item.content {
        @include min-screen($screen__m) {
            margin-top: 48px;
        }

        @include min-screen($screen__l) {
            margin-top: 55px;
        }

        @include min-screen(1170px) {
            margin-top: 57px;
        }

        @include min-screen($screen__xl) {
            margin-top: 59px;
        }
    }

    .item.title.active > {
        .switch,
        .switch:focus,
        .switch:hover {
            color: $color-black-pearl;

            @include min-screen($screen__m) {
                padding-bottom: 15px;
            }

            @include min-screen($screen__l) {
                padding-bottom: 18px;
            }

            @include min-screen(1170px) {
                padding-bottom: 19px;
            }

            @include min-screen($screen__xl) {
                padding-bottom: 20px;
            }
        }
    }
}
