//
//  Magento Theme Styles
//  _____________________________________________

.page-header {
    @include min-screen($screen__m) {
        border-bottom: $border-emerald;
    }

    .header.panel {
        @include min-screen($screen__m) {
            padding-bottom: 0;
            padding-top: 0;
        }

        & > .header.links > li {
            @include min-screen($screen__m) {
                margin: 0;
                padding: 10px;
            }

            &.welcome,
            a {
                @include min-screen($screen__m) {
                    @include lib-link(
                        $_link-color: $header__link-color,
                        $_link-text-decoration: none,
                        $_link-color-visited: $header__link-color-visited,
                        $_link-text-decoration-visited: none,
                        $_link-color-hover: $header__link-color-hover
                    )
                }
            }
        }
    }

    .header.information {
        @include abs-no-display;

        @include min-screen($screen__m) {
            display: inline-block;
        }

        .widget {
            margin: 0;
        }

        .link-list {
            margin: 0;
            padding: 0;

            .link {
                color: $header__color;
                display: inline-block;
                padding: 10px 3px;

                a {
                    height: 20px;

                    @include lib-link(
                        $_link-color: $header__link-color,
                        $_link-text-decoration: none,
                        $_link-color-visited: $header__link-color-visited,
                        $_link-text-decoration-visited: none,
                        $_link-color-hover: $header__link-color-hover
                    );

                    &.social-icon {
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .switcher.currency.switcher-currency {
        @include min-screen($screen__m) {
            float: right;
            color: $header__color;
            padding: 10px 0;
        }

        .options .action.toggle {
            &.active:after,
            &:after {
                @include min-screen($screen__m) {
                    vertical-align: middle;
                    line-height: 14px;
                }
            }
        }
    }

    .logo {
        left: 50%;
        margin: 15px 0;
        transform: translateX(-50%);

        @include min-screen($screen__m) {
            left: unset;
            margin: 0;
            transform: unset;
        }

        .logo-link {
            @include min-screen($screen__m) {
                display: inline-block;
            }

            .logo-img {
                @include min-screen($screen__m) {
                    height: $header-item-height;
                    width: auto;
                }
            }
        }
    }

    .panel.wrapper {
        @include min-screen($screen__m) {
            border-bottom: $border-black-russian;
        }
    }
}

.page-title-wrapper {
    .category-product-count {
        font-family: $heading__font-family__base;
        font-size: $product-count-mobile__font-size;
        text-transform: uppercase;

        @include min-screen($screen__m) {
            font-size: $product-count-desktop__font-size;
        }
    }

    .page-title {
        color: $color-black-pearl;
        font-weight: $font-weight__bold;
        margin-bottom: $indent__s;
        text-transform: uppercase;

        @include min-screen($screen__m) {
            display: inline-block;
            margin-bottom: calc(#{$indent__s} * 1.5);
        }
    }
}

.footer.content {
    .block {
        float: left;
    }
}
