.minicart-wrapper .action.showcart {
    span.counter.qty {
        @include max-screen($screen__m) {
            font-size: $font-size__s;
            height: calc(#{$indent__s} * 1.5);
            line-height: calc(#{$indent__s} * 1.5);
            min-width: calc(#{$indent__s} * 1.5);
            padding: 0;
            position: absolute;
            right: calc(#{$indent__xs} * -1);
            top: calc(#{$indent__xs} * -1);
        }
    }

    .total {
        color: $color-white;
        display: block;
        font-family: $heading__font-family__base;
        font-weight: bold;

        @include max-screen($screen__m) {
            bottom: calc(#{$font-size__s} * -1);
            font-size: $font-size__s;
            position: absolute;
            right: 0;
        }

        &.empty {
            display: none;
        }
    }
}
