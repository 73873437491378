.review-add.amreview-submit-form {
    .review-form {
        display: flex;
        flex-direction: column;

        .action.submit.primary {
            @include lib-button-primary;

            border-radius: 0;
        }
    }

    .field-recaptcha {
        margin-top: $indent__s;
        order: 10;
    }
}
