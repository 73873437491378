.account.page-layout-2columns-left {
    .sidebar-main,
    .sidebar-additional {
        @include min-screen($screen__m) {
            @include lib-layout-column(2, 1, $layout-column__left-width);
        }
    }

    .column.main {
        @include min-screen($screen__m) {
            @include lib-layout-column(2, 2, $layout-column-main__width-2-left);
        }
    }
}
