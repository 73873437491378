//
//  Default/Secondary Buttons
//  _____________________________________________

$button__background: $color-transparent;
$button__border: $border-emerald;
$button__border-radius: 0;
$button__color: $color-charcoal;

$button__hover__color: $color-white-smoke;
$button__hover__background: $color-emerald;

//
//  Primary Buttons
//  _____________________________________________

$button-primary__background: $color-emerald;
$button-primary__border: $border-emerald;
$button-primary__color: $color-white-smoke;

$button-primary__hover__background: lighten($button-primary__background, 5%);
$button-primary__hover__border: $button-primary__border;

$button-primary__active__background: $button-primary__background;
$button-primary__active__border: $button-primary__border;
