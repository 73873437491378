.product.lower .right {
    > .block {
        box-sizing: border-box;
        border: 1px $color-light-gray solid;
        color: $color-black-pearl;
        margin-bottom: $indent__base;
        padding: $indent__base $indent__m;

        @include min-screen($screen__m) {
            margin-left: $indent__base
        }

        a {
            display: block;
            text-decoration: none;
            padding: $indent__xs 0;

            &:before {
                color: $color-emerald;
                content: '>';
                font-weight: $font-weight__bold;
                padding-right: $indent__s;
            }
        }

        h3 {
            color: $color-black-pearl;
            text-transform: uppercase;
            font-weight: $font-weight__semibold;
            font-size: $indent__base;
            margin: $indent__xs 0 15px;
        }

        ul {
            margin-bottom: $indent__s;
            padding-left: 15px;

            li {
                padding: $indent__xs 0;
            }
        }
    }

    .product-lower-right-widget {
        margin-bottom: 20px;
        margin-left: 20px;
    }
}
