body.page-with-filter {
    .columns {
        .sidebar-main {
            order: 0;
        }
    }

    &.filter-active {
        .page-wrapper {
            height: 0;
            margin-top: -999999em;
            visibility: hidden;

            @include min-screen($screen__m) {
                height: initial;
                margin-top: initial;
                visibility: initial;
            }
        }

        .page-header {
            display: none;

            @include min-screen($screen__m) {
                display: initial;
            }
        }

        .columns {
            z-index: 999;
        }
    }
}

.sidebar .filter.block {
    background-color: $color-white-smoke;
    border-radius: $form-element-input__border-radius;
    margin-bottom: 0;
    padding: 15px;

    &.active {
        position: relative;
        visibility: visible;
        z-index: 99;

        // close button
        .filter-title {
            margin: $indent__s;
            margin-top: 0;
            position: fixed;
            right: 0;
            top: 0;
            z-index: 2;

            @include min-screen($screen__m) {
                margin: 0;
                position: initial;
            }

            strong {
                @include lib-icon-font(
                    $icon-remove,
                    $_icon-font-color: $color-black-pearl,
                    $_icon-font-line-height: initial,
                    $_icon-font-position: after,
                    $_icon-font-size: 32px
                );

                background: none;
                border: 0;
                box-shadow: none;
                color: transparent;
                left: auto;
                padding: 0;
                right: 3px;
                top: $indent__s;

                @include min-screen($screen__m) {
                    color: $color-black-pearl;
                }

                &:after {
                    @include min-screen($screen__m) {
                        display: none;
                    }
                }
            }
        }

        .filter-subtitle {
            @extend h4;

            background: transparent;
            display: block;
            height: 50px;
            left: 0;
            margin: 0;
            padding-left: $indent__base;
            position: fixed;
            right: 0;
            top: 15px;
            z-index: 1;

            @include min-screen($screen__m) {
                display: none;
            }
        }

        .filter-options {
            bottom: 0;
            display: block;
            left: 0;
            overflow-x: hidden;
            overflow-y: scroll;
            position: fixed;
            right: 0;
            top: 50px;
            z-index: 10;

            @include min-screen($screen__m) {
                overflow-y: hidden;
                position: initial;
            }

            &-item {
                padding: $indent__s;

                @include min-screen($screen__m) {
                    padding: $indent__s 0;
                }

                .filter-options-content {
                    padding-left: 0;
                }
            }
        }
    }

    @include min-screen($screen__m) {
        margin-bottom: $indent__xl;
        padding-bottom: $indent__s;
    }

    // layered navigation title & mobile button
    .filter-title {
        color: $color-black-pearl;
        font-family: $heading__font-family__base;
        font-size: 25px;
        font-weight: $font-weight__semibold;
        margin-top: $indent__base;
        text-align: center;
        text-transform: uppercase;

        @include min-screen($screen__m) {
            margin: 0;
            padding-bottom: $indent__s;
            text-align: initial;
        }

        strong {
            border: 1px solid $color-gray82;
            border-radius: $form-element-input__border-radius;
            font-size: 25px;
            padding: 15px 75px;
            text-align: center;
            z-index: 2;

            @include min-screen($screen__m) {
                border: none;
                padding: 0;
                text-align: initial;
            }

            &.disabled {
                opacity: 0.5;
            }
        }
    }

    // mobile filter menu title
    .filter-subtitle {
        display: none;
    }

    .filter-content {
        margin: 0 auto;
        width: 100%;

        @include min-screen($screen__m) {
            width: 80%;
        }

        @include min-screen($screen__m) {
            width: 65%;
        }

        @include min-screen($screen__m) {
            width: initial;
        }

        .filter-current {
            border: solid $color-gray82;
            border-width: 1px 0;
            margin: $indent__l 0 0 0;

            @include min-screen($screen__m) {
                border: none;
                margin-top: 0;
            }

            &.active {
                padding-bottom: $indent__l;

                @include min-screen($screen__m) {
                    padding-bottom: 0;
                }

                & + .block-actions {
                    display: block;
                    margin: calc(#{$indent__l} * -1) 0 0 0;

                    @include min-screen($screen__m) {
                        margin-bottom: $indent__base;
                        margin-top: 0;
                    }
                }

                .filter-current-subtitle {
                    @include lib-icon-font-symbol(
                        $_icon-font-content: $icon-up,
                        $_icon-font-position: before
                    );
                }

                .items {
                    display: block;
                }
            }

            & + .block-actions {
                display: none;
                margin-bottom: $indent__base;

                @include min-screen($screen__m) {
                    display: block;
                }
            }

            &-subtitle {
                @include lib-icon-font(
                    $_icon-font-content: $icon-down,
                    $_icon-font-size: 13px,
                    $_icon-font-position: before,
                    $_icon-font-display: block
                );

                border: none;
                display: block;
                margin: 0;
                padding: $indent__base 0;
                position: relative;
                text-transform: capitalize;
                z-index: 1;

                @include min-screen($screen__m) {
                    padding: 0;
                }

                &:before {
                    float: right;
                    font-size: $h4__font-size;
                    top: initial;

                    @include min-screen($screen__m) {
                        display: none;
                    }
                }

                &:after {
                    color: $color-black-pearl;
                    content: ' (' attr(data-count) ')';
                }
            }

            .items {
                display: none;
                margin: $indent__base 0;

                @include min-screen($screen__m) {
                    display: block;
                }

                &.active {
                    display: block;
                }

                .item {
                    position: relative;
                    z-index: 1;

                    .filter-label {
                        font-weight: $font-weight__bold;

                        &:after {
                            content: ': ';
                        }
                    }

                    .filter-value {
                        color: $color-gray82;
                    }
                }
            }
        }
    }

    .filter-options {
        display: none;
        margin: 0;

        @include min-screen($screen__m) {
            display: block;
        }

        &-item {
            padding: $indent__s 0;

            &:last-of-type {
                border-bottom: 0;
            }

            .item {
                a, input {
                    text-decoration: none;
                    vertical-align: middle;
                }
            }
        }

        &-title {
            @include lib-icon-font(
                $_icon-font-color: $color-emerald,
                $_icon-font-content: $icon-down,
                $_icon-font-display: block,
                $_icon-font-line-height: 18px,
                $_icon-font-position: after,
                $_icon-font-size: 25px
            );

            border-bottom: 1px $color-gray82 solid;
            color: $color-black-pearl;
            font-family: $font-family__base;
            font-size: $category-page-primary__font-size;
            padding-bottom: $indent__xs;
            position: relative;
            word-break: break-all;
            z-index: 1;

            &:after {
                float: right;
                font-weight: $font-weight__semibold;
            }

            .active > & {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-up,
                    $_icon-font-position: after
                );
            }
        }

        &-content {
            margin: 0;

            .item {
                line-height: $h3__font-size;
            }

            a:hover {
                color: $color-emerald;
                text-decoration: none;
            }

            .count {
                color: $color-gray68;
                font-weight: $font-weight__light;
                padding-left: $indent__xs;
                padding-right: $indent__xs;

                &:before {
                    content: '(';
                }

                &:after {
                    content: ')';
                }
            }

            .filter-count-label {
                @extend .abs-visually-hidden;
            }
        }
    }
}
