$category-page-primary__font-size: 16px;
$category-page-secondary__font-size: 12px;
$product-page-primary__font-size: 20px;
$product-page-secondary__font-size: 14px;
$product-page-tiered__font-size: 12px;

// Inc / Ex VAT Pricing for mini-basket, cart and checkout
$default-ex-vat__font-size: 14px;
$default-inc-vat__font-size: 11px;
$cart-inc-vat__font-size: 18px;
$summary-ex-vat__font-size: 16px;
$summary-inc-vat__font-size: 10px;
