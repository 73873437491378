//
//  Colours
//  _____________________________________________

$color-charcoal: #434343;
$color-light-gray: #d3d3d3;
$color-gray: #878787;
$color-emerald: #47ce7a;
$color-paris-green: #45cd7c;
$color-silver: #bbbbbb;
$color-red: #ee001c;
$color-black-pearl: #181a1d;
$color-black-russian: #27282b;
$color-transparent: #ffffff00;

$active__color: $color-emerald;
$error__color: $color-red;

$primary__color: $color-charcoal;
$secondary__color: $color-white-smoke;

$page__background-color: $color-white;
