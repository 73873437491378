// Important used here to override inline styling
.page-products .products.wrapper .product-image-wrapper {
    padding-bottom: unset !important;
}

.widget.block {
    .product-image-wrapper {
        padding-bottom: 100% !important;
    }
}

.product-display-lg ~ .products.wrapper.grid .product-image-container {
    width: auto !important;
}

.product-item-info {
    z-index: 0 !important;
}
